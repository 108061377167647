import React, { Fragment } from "react"

import classNames from "classnames"
import PropTypes from "prop-types"

import "./Input.scss"

const Input = ({
  id,
  label,
  isBlock,
  isLarge,
  type,
  isRequired,
  ...remainingProps
}) => {
  switch (type) {
    case "text":
    case "email":
    case "number":
    case "password":
    case "search":
    case "color":
    case "tel":
    case "url":
      return (
        <input
          className={classNames(
            "input",
            { "input--block": isBlock },
            { "input--large": isLarge }
          )}
          type={type}
          required={isRequired}
          {...remainingProps}
        />
      )
    case "range":
      return (
        <input
          className={classNames(
            "input",
            { "input--block": isBlock },
            { "input--large": isLarge }
          )}
          required={isRequired}
          type={type}
          {...remainingProps}
        />
      )
    case "date":
    case "datetime-local":
      return (
        <input
          className={classNames(
            "input",
            { "input--block": isBlock },
            { "input--large": isLarge }
          )}
          required={isRequired}
          type={type}
          {...remainingProps}
        />
      )
    case "file":
      return (
        <input
          className={classNames(
            "input",
            { "input--block": isBlock },
            { "input--large": isLarge }
          )}
          required={isRequired}
          type={type}
          {...remainingProps}
        />
      )
    case "checkbox":
    case "radio":
      return (
        <Fragment>
          <label htmlFor={id}>{label}</label>
          <input
            className="input"
            type={type}
            required={isRequired}
            {...remainingProps}
          />
        </Fragment>
      )
    case "textarea":
      return (
        <textarea
          className={classNames(
            "input",
            { "input--block": isBlock },
            { "input--large": isLarge }
          )}
          required={isRequired}
          {...remainingProps}
        />
      )
    case "submit":
    case "reset":
      return <textarea {...remainingProps} required={isRequired} />
    default:
      return null
  }
}

Input.propTypes = {
  id: PropTypes.string,
  isBlock: PropTypes.bool,
  isRequired: PropTypes.bool,
  isLarge: PropTypes.bool,
  label: PropTypes.string,
  type: PropTypes.string.isRequired,
}

Input.defaultTypes = {
  isBlock: false,
  isLarge: false,
}

export default Input
