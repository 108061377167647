import React from "react"

import classNames from "classnames"
import PropTypes from "prop-types"

import "./Select.scss"

const Select = ({
  id,
  isBlock,
  isLarge,
  isRequired,
  onChange,
  options,
  value,
  name,
  placeholder,
  ...remainingProps
}) => {
  return (
    <select
      className={classNames(
        "input",
        { "input--block": isBlock },
        { "input--large": isLarge }
      )}
      id={id}
      onChange={onChange}
      onBlur={onChange}
      name={name}
      value={value}
      required={isRequired}
      {...remainingProps}
    >
      {placeholder && (
        <option value="none" selected>
          {placeholder}
        </option>
      )}
      {options.map((option, index) =>
        index === 0 ? (
          <option key={index} value="" disabled selected hidden>
            {option.label || option}
          </option>
        ) : (
          <option key={index} value={option.value || option}>
            {option.label || option}
          </option>
        )
      )}
    </select>
  )
}

Select.propTypes = {
  id: PropTypes.string,
  isBlock: PropTypes.bool,
  isLarge: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

Select.defaultTypes = {
  options: [],
  isBlock: false,
  isLarge: false,
}

export default Select
