import React from "react"

import PropTypes from "prop-types"

import Cross from "../../assets/images/cross.svg"
import Input from "../common/Input"
import Select from "../common/Select"

import "./ProductFilter.scss"

const ProductFilter = ({
  filters,
  productTypes,
  setFilters,
  maxPrice,
  minPrice,
}) => {
  const [productMaxPrices, setProductMaxPrices] = React.useState([])
  const [productMinPrices, setProductMinPrices] = React.useState([])

  React.useEffect(() => {
    if (minPrice !== "" && maxPrice !== "") {
      let step = (maxPrice - minPrice) / 5
      if (step % 500 !== 0) {
        if (step % 500 > 250) {
          step += step % 500
        } else {
          step -= step % 500
        }
      }
      let curPrice = minPrice
      const remainingOptions = []
      while (curPrice <= maxPrice) {
        remainingOptions.push({ label: "₹ " + curPrice, value: curPrice })
        curPrice += step
      }
      if (curPrice - step < maxPrice) {
        remainingOptions.push({ label: "₹ " + maxPrice, value: maxPrice })
      }
      setProductMaxPrices([{ label: "Max", value: "" }, ...remainingOptions])
      setProductMinPrices([{ label: "Min", value: "" }, ...remainingOptions])
    }
  }, [minPrice, maxPrice])

  const productTypesOptions = [
    { label: "Product Type", value: "" },
    ...productTypes,
  ]

  return (
    <section className="product-filter">
      <div className="product-filter__container">
        <div className="product-filter__search">
          <Input
            type="search"
            name="search"
            placeholder="Search Products"
            value={filters.search}
            onChange={(e) =>
              setFilters({ ...filters, search: e.target.value.toLowerCase() })
            }
            isBlock
          />
        </div>
        <div className="product-filter__price-min">
          <Select
            options={productMinPrices}
            value={filters.minPrice}
            onChange={(e) =>
              setFilters({ ...filters, minPrice: e.target.value })
            }
            isBlock
          />
          {filters.minPrice && (
            <button
              className="product-filter__clear"
              onClick={() =>
                setFilters({
                  ...filters,
                  minPrice: "",
                })
              }
            >
              <img src={Cross} alt="" />
            </button>
          )}
        </div>
        <div className="product-filter__price-max">
          <Select
            options={productMaxPrices}
            value={filters.maxPrice}
            onChange={(e) =>
              setFilters({ ...filters, maxPrice: e.target.value })
            }
            isBlock
          />
          {filters.maxPrice && (
            <button
              className="product-filter__clear"
              onClick={() =>
                setFilters({
                  ...filters,
                  maxPrice: "",
                })
              }
            >
              <img src={Cross} alt="" />
            </button>
          )}
        </div>
        <div className="product-filter__product-type">
          <Select
            options={productTypesOptions}
            value={filters.productType}
            onChange={(e) =>
              setFilters({ ...filters, productType: e.target.value })
            }
            isBlock
          />
          {filters.productType && (
            <button
              className="product-filter__clear"
              onClick={() =>
                setFilters({
                  ...filters,
                  productType: "",
                })
              }
            >
              <img src={Cross} alt="" />
            </button>
          )}
        </div>
      </div>
    </section>
  )
}

ProductFilter.propTypes = {
  filters: PropTypes.object.isRequired,
  maxPrice: PropTypes.number,
  minPrice: PropTypes.number,
  productTypes: PropTypes.array.isRequired,
  setFilters: PropTypes.func.isRequired,
}

export default ProductFilter
