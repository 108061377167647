import React from "react"

import PropTypes from "prop-types"
import Fade from "react-reveal/Fade"

import { CartContext } from "../../store"
import Button from "../common/Button"
import Link from "../common/Link"

import "./ProductCard.scss"

const ProductCard = ({ product }) => {
  const { addItem } = React.useContext(CartContext)

  const defaultPrice = product.variants[0]?.pricing?.price?.gross?.amount
  const defaultVariantName = product.variants[0]?.name
  const discount = product.pricing?.discount?.gross?.amount
  const hasVariants = !!product.variants[0]

  const addToCart = () => {
    addItem(
      product.variants[0]?.id,
      1,
      product.name,
      defaultPrice,
      defaultVariantName
    )
  }

  return (
    <div className="product-card">
      <Fade>
        <Link className="product-card__image" to={`/product/${product.slug}/`}>
          {product?.thumbnail?.url ? (
            <img
              src={product?.thumbnail?.url}
              alt={product?.thumbnail?.alt}
              loading="lazy"
            />
          ) : (
            <strong>No Image</strong>
          )}
        </Link>
        <Link className="product-card__title" to={`/product/${product.slug}/`}>
          {product.name}
        </Link>
        <div className="product-card__price">
          {discount ? (
            <>
              <span>₹ {defaultPrice + discount}</span>
              <strong>₹ {defaultPrice}</strong>
            </>
          ) : (
            <strong>₹ {defaultPrice}</strong>
          )}
        </div>
        <Button
          color="primary"
          theme="dark"
          isBlock
          onClick={addToCart}
          disabled={!hasVariants}
        >
          {hasVariants ? "Add to Cart" : "Not Available"}
        </Button>
      </Fade>
    </div>
  )
}

ProductCard.propTypes = {
  product: PropTypes.object.isRequired,
}

export default ProductCard
