import React from "react"

import { graphql } from "gatsby"

import PropTypes from "prop-types"

import ProductFilter from "../components/products/ProductFilter"
import ProductList from "../components/products/ProductList"
import SEO from "../components/seo"

const ProductsPage = ({ data }) => {
  const { products, productTypes } = data.saleor

  const [filteredProducts, setFilteredProducts] = React.useState(products)
  const [maxPrice, setMaxPrice] = React.useState("")
  const [minPrice, setMinPrice] = React.useState("")

  React.useEffect(() => {
    let mx = Number.NEGATIVE_INFINITY
    let mn = Number.POSITIVE_INFINITY

    products.edges.forEach((product) => {
      const productPrice = product.node.variants[0].pricing.price.gross.amount
      mn = Math.min(productPrice, mn)
      mx = Math.max(productPrice, mx)
    })

    setMaxPrice(mx)
    setMinPrice(mn)
  }, [products])
  const [filters, setFilters] = React.useState({
    search: "",
    productType: "",
    minPrice: "",
    maxPrice: "",
  })

  React.useEffect(() => {
    if (products) {
      const updatedProducts = JSON.parse(JSON.stringify(products))

      if (filters.search) {
        updatedProducts.edges = updatedProducts.edges.filter((product) =>
          product.node.name.toLowerCase().includes(filters.search)
        )
      }

      if (filters.productType) {
        updatedProducts.edges = updatedProducts.edges.filter(
          (product) => product.node.productType.slug === filters.productType
        )
      }

      if (filters.maxPrice) {
        updatedProducts.edges = updatedProducts.edges.filter(
          (product) =>
            product.node.variants[0].pricing.price.gross.amount <=
            filters.maxPrice
        )
      }

      if (filters.minPrice) {
        updatedProducts.edges = updatedProducts.edges.filter(
          (product) =>
            product.node.variants[0].pricing.price.gross.amount >=
            filters.minPrice
        )
      }

      setFilteredProducts(updatedProducts)
    }
  }, [filters, products])

  return (
    <>
      <SEO title="Products" path="/products" />
      <ProductFilter
        filters={filters}
        setFilters={setFilters}
        productTypes={productTypes.edges.map(({ node }) => ({
          label: node.name,
          value: node.slug,
        }))}
        maxPrice={maxPrice}
        minPrice={minPrice}
      />
      <ProductList hasHeader={false} products={filteredProducts} />
    </>
  )
}

export const pageQuery = graphql`
  query AllProducts {
    saleor {
      productTypes(first: 100) {
        edges {
          node {
            id
            slug
            name
          }
        }
      }
      products(first: 100, filter: { stockAvailability: IN_STOCK }) {
        edges {
          node {
            id
            slug
            name
            seoTitle
            seoDescription
            descriptionJson
            visibleInListings
            isAvailable
            visibleInListings
            isAvailable
            isAvailableForPurchase
            category {
              name
            }
            variants {
              name
              id
              pricing {
                price {
                  gross {
                    amount
                    currency
                  }
                }
              }
            }
            productType {
              name
              slug
            }
            pricing {
              discount {
                gross {
                  amount
                  currency
                }
              }
              onSale
              priceRange {
                start {
                  net {
                    amount
                  }
                }
                stop {
                  net {
                    amount
                  }
                }
              }
            }
            thumbnail {
              url
              alt
            }
            images {
              url
              alt
            }
          }
        }
      }
    }
  }
`

ProductsPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ProductsPage
