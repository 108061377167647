import React from "react"

import { ArrowRight, Cross } from "akar-icons"
import PropTypes from "prop-types"

import Link from "../common/Link"
import SkeletonLoader from "../common/SkeletonLoader"
import ProductCard from "./ProductCard"

import "./ProductList.scss"

const ProductList = ({ isLoading, hasHeader, title, products, slug }) => {
  const availableProducts = products?.edges?.filter(
    (edge) => edge.node.isAvailableForPurchase
  )

  let ProductListContent = null
  const pathname = title?.includes("Similar")
    ? `/${slug}/similar-products/`
    : title?.includes("Products")
    ? "/products"
    : title?.includes("Summer")
    ? "/collection/summer-collection"
    : title?.includes("Winter")
    ? "/collection/winter-sale"
    : ""

  if (isLoading) {
    ProductListContent = (
      <div className="product-list__items">
        {[...Array(10)].map((e) => (
          <SkeletonLoader key={e} />
        ))}
      </div>
    )
  } else if (availableProducts?.length) {
    ProductListContent = (
      <div className="product-list__items">
        {availableProducts.map(({ node }) => (
          <ProductCard key={node.id} product={node} />
        ))}
      </div>
    )
  } else {
    ProductListContent = (
      <div className="product-list__empty">
        <div>
          <Cross style={{ display: "inline" }} size={30} />
        </div>
        <div>No Products</div>
      </div>
    )
  }

  return (
    <section className="product-list">
      <div className="product-list__container">
        {hasHeader && (
          <div className="product-list__header">
            <h2 className="product-list__title">{title}</h2>
            {slug && (
              <Link to={pathname} className="product-list__link">
                See more
                <span className="icon">
                  <ArrowRight size="15" />
                </span>
              </Link>
            )}
          </div>
        )}
        {ProductListContent}
      </div>
    </section>
  )
}

ProductList.propTypes = {
  hasHeader: PropTypes.bool,
  isLoading: PropTypes.bool,
  products: PropTypes.object.isRequired,
  slug: PropTypes.string,
  title: PropTypes.string,
}

ProductList.defaultProps = {
  hasHeader: true,
}

export default ProductList
